import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom';
import { Card, Flex, Text, Button, Tooltip,Input, Form, FormInput,  Dropdown, Loader, Grid as FluentGrid, CardHeader, CardBody, Segment, Avatar, Header, TextArea, Divider, Popup, PillGroup, Pill  } from '@fluentui/react-northstar'
import { AppsIcon, BroadcastIcon, PlugsIcon, DownloadIcon } from '@fluentui/react-icons-northstar'
import { Outlet, useNavigate, useLocation  } from "react-router-dom";
import { getRouteFromLocalStorage, setRouteInLocalStorage } from '../../shared/contexts/Context';
import { formatDate } from '@progress/kendo-intl';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';
import { OperationStatusIndicator } from '../../shared/appcomponents/enums';

import './dashboard.scss';
import { SavedFilter } from '../../shared/appcomponents/filter';
import MonitoringService from '../../services/monitoringservice';
import SettingsService from '../../services/settingsservice';
import { Error403 } from '../../shared/error/403';
import { Error500 } from '../../shared/error/500';
// import { useNavigate } from 'react-router-dom';

const getDatesInRange = (startDate, endDate) => {
    const date = new Date(startDate.getTime());

    const dates = [];

    while (date <= endDate) {
        dates.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }

    return dates;
}



// const dashboardList = [
//     { header: "Monitoring", value: "monitoring", filterType: 4 },
//     { header: "Revenue & Growth", value: "revenue", filterType: 5 }
// ]
const Dashboard = (props) => {

    // const history = useNavigate();
    const dashboardList = [];    
    if(props.user?.Permissions?.some((p) => (p == "Dashboards.Read" || p == "Dashboards.ReadWrite" || p == "Dashboards.Monitoring.Read" || p == "Dashboards.Monitoring.ReadWrite"))){
        dashboardList.push( {header: "Monitoring", value: "monitoring", filterType: 4});
    }    
    if(props.user?.Permissions?.some((p) => (p == "Dashboards.Read" || p == "Dashboards.ReadWrite" || p == "Dashboards.Revenue.Read" || p == "Dashboards.Revenue.ReadWrite"))){
        dashboardList.push(  {header: "Billing", value: "revenue", filterType: 5});
    }
    if(props.user?.Permissions?.some((p) => (p == "Dashboards.Read" || p == "Dashboards.ReadWrite" || p == "Dashboards.BusinessPerformance.Read" || p == "Dashboards.BusinessPerformance.ReadWrite"))){
        dashboardList.push(  {header: "Business Performance", value: "businessPerformance", filterType: 5});
    }

    dashboardList.push(  {header: "Timesheets", value: "timesheets", filterType: 5});

    if(props.user?.Permissions?.some((p) => (p == "Dashboards.TimesheetReport.Read" ))){
        dashboardList.push(  {header: "Timesheet Report", value: "timesheetReport", filterType: 5});
    }

    const [userData, SetUserData] = useState(null);
    const [area, setArea] = React.useState("infra");
    const [areaPane, setAreaPane] = React.useState("critical");
    const [loaded, setLoaded] = React.useState(false);
    const [dashboardName, setDashboardName] = React.useState("");
    const [appliedFilter, setAppliedFilter] = React.useState(null);
    const [appliedFilterType, setAppliedFilterType] = React.useState(null);
    const [onCallData, setOnCallData] = useState(null);

    const [onCallPageData, setOnCallPageData] = useState(null);
    const [restrictedDates, setRestrictedDates] = useState([]);
    const [occupiedOnCallDates, setOccupiedOnCallDates] = useState(null);
    const[visibleUpdateContact,setVisibleUpdateContact] = useState(null);
    const [isValid, setIsValid] = useState(false);

    const[secondaryContact,setSecondaryContact]= useState(props?.user?.SecondaryContact);
    const[primaryContact,setPrimaryContact]=useState(props?.user?.PrimaryContact);
    const[appUserId,setAppUserId]=useState(props?.user?.AppUserId);

    const[selectedDashboardDDL,setselectedDashboardDDL]=useState(null);
   
    let navigate = useNavigate();

    const routeChange = (path) => {
        navigate(path);
    }

    const loc = useLocation();

    useEffect(() => {
        if(loc && loc.pathname && loc.pathname.length > 0){
            if(loc.pathname === '/dashboards/businessPerformance'){
                setDashboardName('businessPerformance');
            }
            else if(loc.pathname === '/dashboards/monitoring/infra/summary'){
                setDashboardName('monitoring');  
            }
            else if(loc.pathname === '/dashboards/revenue'){
                setDashboardName('revenue');  
            } 
            else if(loc.pathname === '/dashboards/timesheets'){
                setDashboardName('timesheets');  
            } 
            else if(loc.pathname === '/dashboards/timesheetReport'){
                setDashboardName('timesheetReport');  
            } 
        }
       
    }, [loc])

    React.useEffect(() => {
       
        let route;
        let _dash;
        props.viewStatus.setPageStatus(null);
        props.viewStatus.InformChildPageStatus(null);
        if(dashboardList.length > 0){
            if(loc && loc.pathname && loc.pathname.length > 0){
                if(loc.pathname === '/dashboards' || loc.pathname === '/dashboards/'){
                   
                    if(dashboardList.findIndex(x => x.value == "businessPerformance") != -1){
                        _dash = "businessPerformance";
                        route = "/dashboards/businessPerformance";
                    }
                    else if(dashboardList.findIndex(x => x.value == "monitoring") != -1){
                        _dash = "monitoring";
                        route = "/dashboards/monitoring/infra/summary";
                    }
                    else if(dashboardList.findIndex(x => x.value == "revenue") != -1){
                        _dash = "revenue";
                        route = "/dashboards/revenue";
                    }
                    else if(dashboardList.findIndex(x => x.value == "timesheets") != -1){
                        _dash = "timesheets";
                        route = "/dashboards/timesheets";
                    }
                    else if(dashboardList.findIndex(x => x.value == "timesheetReport") != -1){
                        _dash = "timesheetReport";
                        route = "/dashboards/timesheetReport";
                    }

                    if(_dash && _dash.length > 0){
                        setDashboardName(_dash);
                        navigate(route);
                    }

                }
            }

            // let lastRoute = getRouteFromLocalStorage('contentUrl_dashboards');
            // let lastRoute;
            // let _dash;
            // // if (lastRoute && lastRoute.split('/').length > 2) {
            // //     _dash = lastRoute.split('/')[2];
            // // }

            // if(dashboardList && dashboardList.length > 0){
            //     if(dashboardList.findIndex(x => x.value == "businessPerformance") != -1){
            //         _dash = "businessPerformance";
            //         lastRoute = "/dashboards/businessPerformance";
            //     }
            //     else if(dashboardList.findIndex(x => x.value == "monitoring") != -1){
            //         _dash = "monitoring";
            //         lastRoute = "/dashboards/monitoring/infra/summary";
            //     }
            //     else if(dashboardList.findIndex(x => x.value == "revenue") != -1){
            //         _dash = "revenue";
            //         lastRoute = "/dashboards/revenue";
            //     }
            //     else if(dashboardList.findIndex(x => x.value == "timesheets") != -1){
            //         _dash = "timesheets";
            //         lastRoute = "/dashboards/timesheets";
            //     }
            //     else if(dashboardList.findIndex(x => x.value == "timesheetReport") != -1){
            //         _dash = "timesheetReport";
            //         lastRoute = "/dashboards/timesheetReport";
            //     }
            // }

            // // else{
                
            // // }

            // if(!(_dash && dashboardList.findIndex(x => x.value.toLowerCase() == _dash.toLowerCase()) != -1)){
            //     _dash = "businessPerformance";
            //     lastRoute = "/dashboards/businessPerformance";
            // }
            // // setRouteInLocalStorage('contentUrl_dashboards', lastRoute);
            // // setDashboardName(_dash);
            // setAppliedFilterType(dashboardList.find((d) => d.value === _dash)?.filterType);
            // navigate(lastRoute);
            props.acquireToken(authSuccessCallback);
        }
        else{
            props.viewStatus.setPageStatus(401);
        }
    }, []);

    
    const submitHandler = (e) => {
        e.preventDefault();
      };

    const handleDashboardChange = (option) => {
        
        props.viewStatus.setPageStatus(null);
        props.viewStatus.InformChildPageStatus(null);
        let dashName = option.value;
        if (dashName !== dashboardName) {
            setDashboardName(structuredClone(dashName));
            setAppliedFilterType(option.filterType);
            setAppliedFilter(null);
            let path = '';
            if (dashName == 'monitoring') {
                path = `/dashboards/${dashName}/infra/alarms?type=critical`;
            }
            else if(dashName == 'businessPerformance'){
                path = `/dashboards/${dashName}`;
            }
            else {
                path = `/dashboards/${dashName}`;
            }
            // setRouteInLocalStorage('contentUrl_dashboards', path);
            navigate(path);
            
        }
    };

    const handleFilterChange = (selectedFiler) => {
        setAppliedFilter(selectedFiler);
        setLoaded(false);
        setTimeout(function () {
            setLoaded(true);
        }, 1000);
    }

    
    const checkIsValid = () => {
        if (
        primaryContact !== undefined &&
         primaryContact?.length > 0
        ) {
          setIsValid(true);
        } else setIsValid(false);
      };

      const submitClickHandler = () => {
        props.acquireToken(authSuccessContactUpdateCallback);
        setVisibleUpdateContact(false);
        setIsValid(false);
      };
      const authSuccessContactUpdateCallback = (token) => {
        let userData = {
            AppUserId: appUserId,     
            PrimaryContact: primaryContact,
            SecondaryContact: secondaryContact
          }
        if (token) {
          MonitoringService.UpdateContact(token, userData).then((response) => {
            if(response && response.status==200){
              response=response.data;
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Success);
                 
            } 
            else if(response && response.status==401){
              
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
    
            }
            else {
              props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
            }
          });
        }
      };
    const handleUpdateContactClickOpen=()=>{
        setVisibleUpdateContact(true);
        setIsValid();
    }

    const handleUpdateContactClickClose=()=>{
        setVisibleUpdateContact(false);
        setIsValid(false);
    }

    const handleRDPHandlerClick = () => {
        props.acquireToken((token) => {
            if (token) {
                MonitoringService.GetRDPHandler(token).then((response) => {
                    if(response && response.status==200){
                        response=response.data;
                        const url = window.URL.createObjectURL(new Blob([response]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'rdp.bat');
                        document.body.appendChild(link);
                        link.click();                
                    } 
                    else{
                        props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
                    }               
                });
            }
        });
    }

    const authSuccessCallback = (token) => {
        
        if (token) {
            MonitoringService.GetOnCallDetails(token).then((response) => {
                if(response && response.status==200){
                    response=response.data;
                    response.PeoplePickerList = response.EligibleUsers.map((m) => ({ key: m.AppUserId, header: m.DisplayName, value: m.AppUserId, content: m.AppUserEmail, primarycontact: m.PrimaryContact }))
                    let _onCallData = {
                        CurrentMemberPrimary: [],
                    }
                    let runningMaxEndDate = new Date(new Date().setHours(0, 0, 0, 0));
                    response.MemberData.CurrentMembers.map((m) => {
                        if (m.MemberTypeId == 1) {
                            _onCallData.CurrentMemberPrimary.push({ ...m, StartDate: new Date(m.StartTime), EndDate: new Date(m.EndTime) });
                        }
                    });
                    setOnCallData(_onCallData);
                }
                else if(response && response.status){
                    props.viewStatus.setPageStatus(response.status);
                }
                else{
                    props.viewStatus.setPageStatus(500);
                }
                setLoaded(true);
            });
        }
    };

    return loaded ?   
    props.viewStatus.pageStatus == 401 ? <Error403 /> :
    props.viewStatus.pageStatus == 500 ? <Error500 /> : 
    (<div className='dashboard pt-1'>
        {
            dashboardName != ""
                ?
                <div className='dashboard-container'>
                    <OperationStatusIndicator status={props.viewStatus.operationStatus} revertStatusToNull={() => props.viewStatus.DisplayOperationStatus(null)} />
                    <div className='dashboard-header mt-2 mb-4'>
                        <Flex gap="gap.small" space="between">
                            <Flex.Item>
                                <Flex gap="gap.small" vAlign='center' className='header-flex flex-sm-column'>
                                    <Text weight='bold' content="Dashboard:" />
                                    <div style={{width: '250px'}}>
                                    <Dropdown fluid
                                        className="mb-1 dashboard-selectbox" styles={{ width: 200 }}
                                        items={dashboardList}
                                        placeholder="Select your dashboard"
                                        // defaultValue={
                                        //     dashboardList.find((d) => d.value === dashboardName)
                                        // }
                                        onChange={(e, selectedOption) =>
                                            handleDashboardChange(selectedOption.value)
                                        }
                                        value={
                                            dashboardList.find((d) => d.value === dashboardName)
                                        }
                                    />
                                    </div>
                                    
                                </Flex>
                            </Flex.Item>
                                <Flex gap="gap.small" push  className='header-flex flex-sm-column' style={{marginRight: '-2px'}}>
                                <Flex.Item>
                                    <Flex gap='gap.small' vAlign='center' >
                                        <Flex column>
                                          {dashboardName == 'monitoring' ?  <Button weight="bold" content="Update Contact:" title='Update Contact Details'  onClick={handleUpdateContactClickOpen}/>:<></>}
                                        </Flex> 
                                        <Flex column>
                                            <Text weight="bold" content="On Call:" />
                                        </Flex>
                                        <Flex >
                                            <Avatar name={onCallData?.CurrentMemberPrimary[0]?.DisplayName} size="medium" />
                                            <div>
                                                {
                                                    onCallData?.CurrentMemberPrimary[0] != null ?
                                                        <Flex column>
                                                            <Text weight="bold" size='medium' content={onCallData?.CurrentMemberPrimary[0]?.DisplayName} className="text-primary" />
                                                            <p className='m-0'>
                                                                <Text weight="bold" content={formatDate(new Date(onCallData?.CurrentMemberPrimary[0]?.StartTime), "dd-MMM-yyyy")} />
                                                                <Text content={" to "} />
                                                                <Text className="mb-2" weight="bold" content={formatDate(new Date(onCallData?.CurrentMemberPrimary[0]?.EndTime), "dd-MMM-yyyy")} />
                                                            </p>
                                                        </Flex>
                                                    : <></>
                                                }
                                            </div>
                                        </Flex>
                                    </Flex>
                                </Flex.Item>
                                <Flex.Item>
                                    {dashboardName == 'monitoring' ? <Button title='Click here to download batch file, run it in administrator mode and install rdp association' onClick={handleRDPHandlerClick} styles={{ height: 'auto', paddingRight: '0' }} content="RDP Handler" size="small" icon={<DownloadIcon />} text />
                                        : <></>}
                                </Flex.Item>
                            </Flex>
                            
                            {/* <Flex.Item push>
                            <Flex gap="gap.small" vAlign='center'>
                                <SavedFilter filterType={appliedFilterType} appliedFilter={appliedFilter} onSubmit={(selctedFilter) => {handleFilterChange(selctedFilter)} } /> 
                            </Flex>
                        </Flex.Item> */}
                        </Flex>
                    </div>
                    <div>
                  {visibleUpdateContact &&(
                    <Dialog title={"Update-Contact"}
                    onClose={handleUpdateContactClickClose} 
                    className='mid-wide restricted-width'
                    >
                    
                      <Form onSubmit={submitHandler}>
                        <FormInput
                          label="Primary Contact"
                          name="PrimaryContact"
                          id="PrimaryContact-inline"
                          fluid
                          required
                          value={primaryContact}
                          onKeyUp={checkIsValid}
                          onChange={(value) =>
                            // SetUserData({
                            //   ...userData,
                            //   PrimaryContact: value.target.value,
                            // })
                            
                            setPrimaryContact(value.target.value)

                          }
                        />
                        <FormInput
                          label="Secondary Contact"
                          name="SecondaryContact"
                          id="SecondaryContact-inline"
                          fluid
                          disabled={false}
                          readOnly={false}
                          value={secondaryContact}
                          onChange={(value) =>
                            setSecondaryContact(value.target.value)
                            // SetUserData({
                            //   ...userData,
                            //   SecondaryContact: value.target.value,
                            // })
                         }
                        />


                        

                      <DialogActionsBar>
                      <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                            disabled={isValid === false}
                            onClick={submitClickHandler}
                          >
                            {" "}
                            Submit
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleUpdateContactClickClose}
                            >
                            {" "}
                            Cancel
                          </button>
                        </DialogActionsBar>
                      </Form>

                    </Dialog>
                    
                  )}
                </div>
                    <div className='dashboard-body' style={{marginTop: '-10px'}}>
                        {
                        loaded ? 
                        props.viewStatus.childpageStatus == 401 ? <Error403 /> :
                        props.viewStatus.childpageStatus == 500 ? <Error500 /> :
                        <Outlet /> 
                        : <Loader />
                        } 
                        </div>
                </div>
                :
                <Loader />
        }
    </div>)
    :""
};

export { Dashboard }

