import React, { useState, useEffect } from "react";
import { Dialog as FluentDialog, Status, Header, Grid as FluentGrid, Flex, Card, CardHeader, CardBody, Button, Input,
	Form, FormInput, Loader, EditIcon, TrashCanIcon, Alert, List, ListItem, Segment, FormRadioGroup, TextArea, Text, RadioGroup, Label, Datepicker , Dropdown} from "@fluentui/react-northstar";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { AddIcon, SearchIcon, AcceptIcon, CloseIcon, FilterIcon,FluidIcon,DownloadIcon } from "@fluentui/react-icons-northstar";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { WindowContext } from "../../shared/contexts/Context";
import SettingsService from "../../services/settingsservice";
import { process } from "@progress/kendo-data-query";
import { CellAWSNotifications, CellEmail} from '../../shared/appcomponents/custom-grid-cells';
import { OPERATIONSTATUS } from '../../shared/appcomponents/enums';

const SettingsAWSNotifications = (props) => {

	const [AWSNotifications, setAWSNotifications] = React.useState(null);
	// const [alertData, setalertData] = React.useState(null);
	const [isLoaded, SetLoaded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [editMode, setEditMode] = React.useState(false);
	const [isValid, setIsValid] = useState(false);
	const [openDeleteConfirmationDialog, setDeleteConfirmationDialog] = useState(false);
    const [deleteAlertObj, setDeleteAlertObj] = useState(null);

	const [isAlertIdValid, SetisAlertIdValid] = React.useState(true);
	const [isAlertIdDisabled, setIsAlertIdDisabled] = useState(false);

	const [selectedStartDate, setSelectedStartDate] = useState(new Date());
	const [selectedEndDate, setSelectedEndDate] = useState(new Date());
	const [selectedNotificationType, setNotificationType] = React.useState({ key: 0,  header: 'All', value: 'All' });
	const notificationTypes = [	{  key: 0,  header: 'All', value: 'All'  },
															{  key: 1,  header: 'Delivery', value: 'Delivery'  }, 
															{  key: 2,  header: 'Bounce', value: 'Bounce'  }, 
															{  key: 3,  header: 'Complaint', value: 'Complaint'  }];
const[downloadingMap, setDownloadingMap] = React.useState({});
	const [destEmail, setDestEmail] = useState("");
	const [dataState, setDataState] = React.useState({
		sort: [
			{
				field: "NotificationTimestamp",
				dir: "desc",
			},
		],
		take: 10,
		skip: 0,
	});

	const emailDaysItems = [
		{
			name: 'Today',
			key: 'today',
			label: 'Today',
			value: '0',
		},
		{
			name: 'Select Date Range',
			key: 'all',
			label: 'Select Date Range:',
			value: '-1',
		},
	];

	const [emailDaysCheckedValue, setEmailDaysCheckedValue] = React.useState('0')

	const emailDaysChange = (e, data) => {
		
		setEmailDaysCheckedValue(data.value);
		// if(data.value != '-1'){ 
		// 	SetLoaded(false);
		// 	props.acquireToken((token) => {
		// 		if (token) {
		// 			let _params = {
		// 				EmailDays: data.value,
		// 			};
		// 			SettingsService.GetAWSNotifications(token, _params).then((response) => {
		// 				if (response && response.status==200) {
		// 					response = response.data;
		// 					setAWSNotifications(response);
							
		// 				}
		// 				else if(response && response.status==401){
		// 					props.viewStatus.InformChildPageStatus(response.status);
		// 					}
		// 					else {
		// 					props.viewStatus.InformChildPageStatus(500);
		// 					}        
		// 					SetLoaded(true);
		// 			});
		// 		}
		// 	});
		// }
	};

	const applyDateFilter = () => {
		SetLoaded(false);
		setActive("false");
		

		let sd = new Date(selectedStartDate);
		let startDate = sd.getFullYear() + '/' + (sd.getMonth() +1) + '/' + sd.getDate();
		let ed = new Date(selectedEndDate) ;
		let endDate = ed.getFullYear() + '/' + (ed.getMonth() +1) + '/' + ed.getDate();
		
		props.acquireToken((token) => {
			if (token) {
				let _params = {
					EmailDays: emailDaysCheckedValue,
					startDate: startDate,
					endDate: endDate,
					notificationType : selectedNotificationType.value,
					destinationEmail : destEmail
				};
				SettingsService.GetAWSNotifications(token, _params).then((response) => {
					if (response && response.status==200) {
						response = response.data;
						setAWSNotifications(response);						
					}
					else if(response && response.status==401){
						props.viewStatus.InformChildPageStatus(response.status);
						}
						else {
						props.viewStatus.InformChildPageStatus(500);
						}        
						SetLoaded(true);
				});
			}
		});
	}
	
React.useEffect(() => {
    props.acquireToken(authSuccessCallback);
  }, []);

  const authSuccessCallback = (token) => {
    if (token) {
			let _params = {
				EmailDays: emailDaysCheckedValue,
			};
      SettingsService.GetAWSNotifications(token, _params).then((response) => {
        if (response && response.status==200) {
			response = response.data;
          setAWSNotifications(response);
        }
		else if(response && response.status==401){
		props.viewStatus.InformChildPageStatus(response.status);
		}
		else {
		props.viewStatus.InformChildPageStatus(500);
		}        
		SetLoaded(true);
      });
    }
  };

	const filterGridData = (value) => {
    let filter = {
      logic: "or",
      filters: [{ field: "NotificationType", operator: "contains", value: value },
								{ field: "SourceEmail", operator: "contains", value: value },
								{ field: "SourceIP", operator: "contains", value: value },
								{ field: "DestinationEmail", operator: "contains", value: value },
								],
    };
    setDataState({ ...dataState, filter: filter });
  };

	const ActionIconVersions = (props) => {
    return (
      <td style={{ textAlign: "center" }}>
        {/* <Button className="mr-3" styles={{ height: 'auto' }} iconOnly size="small" icon={<EditIcon />} text onClick={() => handleAddEditClickOpen(props.dataItem, true)}/> */}
        <Button
          styles={{ height: "auto" }}
          iconOnly
          size="small"
          icon={<TrashCanIcon />}
          text
          onClick={() => {
            // setDeleteAlertObj(props.dataItem);
            // setDeleteConfirmationDialog(true);
          }}
        />
      </td>
    );
  };

	const DetailComponentSm = (props1) => {
    const dataItem = props1.dataItem;
		
    return (
      <section>
        <p>
					Mail Timestamp: <strong>{dataItem.MailTimestamp}</strong>
        </p>
        <p>
					Source Email: <strong>{dataItem.SourceEmail}</strong>
        </p>
        <p>
					Source IP: <strong>{dataItem.SourceIP}</strong>
        </p>
        <p>
					Destination Email: <strong>{dataItem.DestinationEmail}</strong>
        </p>
        <p>
					Notification Timestamp: <strong>{dataItem.NotificationTimestamp}</strong>
        </p>
				<p>
					Bounce Type: <strong>{dataItem.BounceType}</strong>
        </p>
				<p>
					Bounce Sub Type: <strong>{dataItem.BounceSubType}</strong>
        </p>
				<p>
					Action: <strong>{dataItem.Action}</strong>
        </p>
				<p>
					Caller Identity: <strong>{dataItem.CallerIdentity}</strong>
        </p>
				<p>
					Status: <strong>{dataItem?.Status}</strong>
        </p>
				<p>
					Diagnostic Code: <strong>{dataItem?.DiagnosticCode}</strong>
        </p>
				<p>
					Download: <Button content="Download" loading={downloadingMap[dataItem?.MessageId]}  onClick={() => handleDownload(dataItem?.MessageId, dataItem?.MailTimestamp)} icon={<DownloadIcon size="medium" className='mr-1' />} />
        </p>
      </section>
    );
  };

	const DetailComponentLg = (props1) => {
    const dataItem = props1.dataItem;

    return (
      <table>
        <tbody>
          <tr>
            <td>Bounce Type: <strong>{dataItem.BounceType}</strong></td>
            <td>Bounce Sub Type: <strong>{dataItem.BounceSubType}</strong></td>
            <td>Action: <strong>{dataItem.Action}</strong></td>
            <td>Caller Identity: <strong>{dataItem.CallerIdentity}</strong></td>
            <td>Status: <strong>{dataItem?.Status}</strong></td>
            <td>Diagnostic Code: <strong>{dataItem?.DiagnosticCode}</strong></td>
          </tr>
        </tbody>
      </table>
    );
  };

	const expandChange = (event) => {
    let newData = AWSNotifications.map((item) => {
      if (item.Id === event.dataItem.Id) {
        item.expanded = !event.dataItem.expanded;
      }

      return item;
    });
    setAWSNotifications(newData);
  };

	const  addDays = (date, days) => {
		let result = new Date(date);
		result.setDate(result.getDate() + days);
		return result;
	}

	const CellEmailCol = (prop) => {
    return (
        <CellEmail {...prop} acquireToken={props.acquireToken} />
    );
 }

 const [isActive, setActive] = useState("false");
 const ToggleClass = () => {
   setActive(!isActive); 
  };

	const handleNotificationTypeChange = (_selectedType) => {
		setNotificationType(_selectedType);
	}

	const handleDownload = (messageId, mailTimestamp) => {
    props.acquireToken((token) => {
			let _params = {
					messageId: messageId,
					mailTimestamp: mailTimestamp
			};
			if (token) {
				debugger
					setDownloadingMap({...downloadingMap, [messageId]: true});
					SettingsService.DownloadAWSNotification(token, _params).then((response) => {
							if (response && response.status == 200) {
									response = response.data;
									const href = URL.createObjectURL(response);

									// create "a" HTML element with href to file & click
									const link = document.createElement('a');
									link.href = href;
									link.setAttribute('download', 'notification.txt'); //or any other extension
									document.body.appendChild(link);
									link.click();

									// clean up "a" element & remove ObjectURL
									document.body.removeChild(link);
									URL.revokeObjectURL(href);
							}
							else if (response && response.status == 401) {
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Unauthorized);
							}
							else {
									props.viewStatus.DisplayOperationStatus(OPERATIONSTATUS.Failed);
							}
							setDownloadingMap({...downloadingMap, [messageId]: false});
					});
			}
		});
   
  };

	const CellDownloadAWS = (props) => {
  
		const data = props.dataItem;
		return (
			<td>
				<Button content="Download" loading={downloadingMap[data]}  onClick={() => handleDownload(data?.MessageId, data?.MailTimestamp)} icon={<DownloadIcon size="medium" className='mr-1' />} />
			</td>
		);
	
	};

	return isLoaded ? (
    <WindowContext.Consumer>
    {(context) => (
		<>
			<div className="row h-100">
				<div className="col-md-12 h-100">
					<Card className="card-border bg-white h-100" fluid>
						<CardHeader className="mb-2">
							<div className="d-flex justify-content-between position-relative">
								<div className="f-size-18">AWS Notifications</div>
								<Button styles={{padding:'0 12px'}} icon={<FilterIcon />} content={"Filters"} title="Filters" onClick={ToggleClass} className="filetr-icon" circular />
								<Flex gap="gap.small" className={isActive ? "email-log-filter" : "active email-log-filter"}>
									<RadioGroup
										checkedValue={emailDaysCheckedValue}
										items={emailDaysItems}
										onCheckedValueChange={emailDaysChange}
										// value={emailDaysSelected}
									/>
									<Text content="Start Date" className="date-filter-text"  size="medium" weight="regular"
									 disabled={emailDaysCheckedValue == "-1" ? false:true} />
									<Datepicker allowManualInput={false}  className="date-filter" 
										selectedDate={selectedStartDate} disabled={emailDaysCheckedValue == "-1" ? false:true} 
										maxDate={selectedEndDate}
										onDateChange={ (e, data) => {
												setSelectedStartDate(data.value);
											}} />
									<Text content="End Date"  className="date-filter-text" size="medium" weight="regular"
										disabled={emailDaysCheckedValue == "-1" ? false:true}/>
									<Datepicker allowManualInput={false}  className="date-filter" 
										selectedDate={selectedEndDate}  disabled={emailDaysCheckedValue == "-1" ? false:true} 
										minDate={selectedStartDate}
										onDateChange={(e, data) => {
												setSelectedEndDate(data.value);
											}}/>

									<Text content="Notification Type"  className="date-filter-text" size="medium" weight="regular"
										disabled={emailDaysCheckedValue == "-1" ? false:true}/>

									<Dropdown value={selectedNotificationType} items={notificationTypes} className="aws-notification-type date-filter"
										onChange={(e, selectedItem) => {
											handleNotificationTypeChange(selectedItem.value);
										}}
										disabled={emailDaysCheckedValue == "-1" ? false:true}
										fluid
									/>

									<Text content="Destination Email"  className="date-filter-text" size="medium" weight="regular"
										disabled={emailDaysCheckedValue == "-1" ? false:true}/>
									<Input value={destEmail} className="aws-destEmail date-filter" 	disabled={emailDaysCheckedValue == "-1" ? false:true}
										onChange={(e, { name, value }) => {
											setDestEmail(value);
										}} 
										 />
									<Flex gap="gap.small" className="mt-2">
										<Button content="Apply" className="aws-applyFilter" 
											onClick = {applyDateFilter}/>
										<Button content="Cancel" className="aws-applyFilter" 
											onClick = {() => setActive("false")}/>
									</Flex>
									
								</Flex>
							</div>
							
						</CardHeader>
						<CardBody>
							<div className="tbl-search float-right text-right mb-2">
								<Input
									onChange={(e, { name, value }) => {
										filterGridData(value);
									}}
									inverted
									icon={<SearchIcon />}
									placeholder="Search..."
									className="border-gray"
								/>
							</div>
							<div className='grid-with-search'>
								<Grid
									style={{
										height: "100%",
									}}
									// pageable={true}
									pageable={{
										buttonCount: 3
									}}
									sortable={true}
									data={process(AWSNotifications, dataState)}
									{...dataState}
									onDataStateChange={(e) => {
										setDataState(e.dataState);
									}}
									detail={
										context.mediaCategory.sm
											? DetailComponentSm
											: DetailComponentLg
									}
									 expandField="expanded"
									 onExpandChange={expandChange}
								>
									{/* <Column field="Id"  title="Id"  cell={CellAWSNotifications} width={50}/> */}
									<Column field="NotificationType"  title="Notification Type" cell={CellAWSNotifications} width={80}/>
									
									{!context.mediaCategory.sm && (
                      <Column field="MailTimestamp" title="Mail Timestamp"cell={CellAWSNotifications} width={100}  />
									)}

									{!context.mediaCategory.sm && (
                      <Column field="SourceEmail" title="Source Email"cell={CellAWSNotifications} width={100}  />
									)}

									{!context.mediaCategory.sm && (
                      <Column field="SourceIP" title="Source IP"cell={CellAWSNotifications} width={60}  />
									)}

									{!context.mediaCategory.sm && (
                      <Column field="DestinationEmail" title="Destination Email"cell={CellAWSNotifications} width={100}  />
									)}

									{!context.mediaCategory.sm && (
                      <Column field="NotificationTimestamp" title="Notification Timestamp"cell={CellAWSNotifications} width={100}  />
									)}

									{!context.mediaCategory.sm && (
                      <Column field="MessageId" title="Download" cell={CellDownloadAWS} width={70}  />
									)}

									{/* <Column field="MailTimestamp"  title="Mail Timestamp" cell={CellAWSNotifications} width={100}/>
									<Column field="SourceEmail"  title="Source Email" cell={CellAWSNotifications} width={100}/>
									<Column field="SourceIP"  title="Source IP" cell={CellAWSNotifications} width={100}/>
									<Column field="DestinationEmail"  title="Destination Email" cell={CellAWSNotifications} width={100}/>
									<Column field="NotificationTimestamp"  title="Notification Timestamp" cell={CellAWSNotifications} width={100}/> */}
									{/* <Column field="EmailTo" title="To Address"   cell={AWSNotifications}  width={220}/>
									<Column field="EmailCC"  title="CC Address"  cell={AWSNotifications}  width={200}/>
									<Column field="EmailBCC"  title="BCC Address"  cell={AWSNotifications} width={200}/>
									<Column field="InstanceName"  title="Instance" width={150}/>
									<Column field="AlertDetails"  title="Alert" cell={AWSNotifications} width={200}/>
									<Column field="EmailSentDate" title="Email Sent Date"  width={200} />
									<Column field="Status" title="Status" width={100}/> */}
								</Grid>
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
		</>
	)}
	</WindowContext.Consumer>
	): (
    <Loader />
  );;
};

export { SettingsAWSNotifications };
